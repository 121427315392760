@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body,
  input,
  textarea,
  button {
    @apply font-medium text-black-base;
  }

  h1,
  h2 {
    @apply text-2xl text-brand;
  }

  h3 {
    @apply text-xl text-brand;
  }

  input[type='search']::-webkit-search-cancel-button {
    @apply cursor-pointer;
  }

}

:root {
  --font-bold: 1000;
  --font-demi: 700;
  --font-medium: 500;
  --font-regular: 400;
}

body {
  margin: 0;
  font-family: 'NapsterSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--font-bold);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.font-bold{
  font-weight: var(--font-bold);
}
.font-semibold{
   font-weight: var(--font-demi);
 }
 .font-medium{
  font-weight: var(--font-medium);
 }
 .font-normal{
  font-weight: var(--font-regular);
 }

.field-label {
  @apply flex justify-between w-full text-sm text-brand font-semibold cursor-pointer mb-2;
}

.field {
  @apply rounded block w-full px-3 py-3 text-left focus:ring-transparent border border-gray-300 focus:border-gray-400 focus:outline-none text-sm;
}

.field--invalid {
  @apply border-red-300 text-red-900 focus:border-red-500;
}

.td {
  @apply px-3 py-5 whitespace-nowrap text-sm h-[80px];
}

.tr {
  @apply odd:bg-gray-50 cursor-default;
}

.tr-hover {
  @apply hover:bg-gray-100 cursor-pointer;
}

/* Carousel custom styles */

.carousel {
  padding-bottom: 15px;
}

.carousel .thumb img {
  width: 70px;
  height: 70px;
  max-height: 70px;
  min-height: 70px !important;
  object-fit: cover;
  object-position: center;
}

.carousel .thumbs {
  margin-left: 15px;
}

.carousel .control-arrow {
  display: none;
}

/* Calendar custom styles */

.react-datepicker-popper {
  @apply z-20 w-max;
}

.react-datepicker {
  @apply font-[NapsterSans] border-none flex rounded shadow bg-neutral-100 px-4 pb-3 pt-5;
}

.react-datepicker__header {
  @apply pb-2 mb-2 border-none bg-neutral-100;
}

.react-datepicker__month {
  @apply bg-neutral-100 m-0 p-0;
}

.react-datepicker__navigation {
  @apply top-7;
}

.react-datepicker__navigation-icon::before {
  @apply font-medium border-neutral-900;
}

.react-datepicker__day {
  @apply py-1 w-9 mx-0;
}

.react-datepicker__day.react-datepicker__day--range-end {
  @apply bg-accent text-white;
}

.react-datepicker__day.react-datepicker__day--in-selecting-range {
  @apply bg-blue-300;
}

.react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--outside-month {
  @apply text-white;
}

.react-datepicker__day--in-range {
  @apply bg-blue-300;
}

.react-datepicker__day--keyboard-selected:not(.react-datepicker__day--selected) {
  @apply bg-accent rounded-full font-medium;
}

.react-datepicker__day--keyboard-selected.react-datepicker__day--in-range,
.react-datepicker__day--keyboard-selected.react-datepicker__day--in-selecting-range {
  @apply bg-blue-300 text-white;
}

.react-datepicker__current-month {
  @apply font-semibold text-neutral-900;
}
.react-datepicker-year-header {
  @apply text-neutral-900;
}

.react-datepicker__day-names {
  @apply pt-3 border-b border-solid border-gray-300;
}

.react-datepicker__day-name {
  @apply text-gray-400 mx-1;
}

.react-datepicker__day {
  @apply rounded-full hover:rounded-full;
}

.react-datepicker__day--selected,
.react-datepicker__time-list-item--selected {
  @apply bg-accent font-medium !important;
}

.react-datepicker__triangle {
  @apply hidden;
}

.react-datepicker-time__header {
  @apply text-white;
}

.react-datepicker__day--outside-month {
  @apply text-gray-400 pointer-events-none;
}

.react-datepicker__input-time-container {
  @apply mt-0 mb-0;
}

.react-datepicker--time-only .react-datepicker__input-time-container {
  @apply !m-0;
}

.react-datepicker-time__caption {
  @apply hidden !important;
}

.react-datepicker__year-wrapper {
  @apply w-36 justify-center;
}

.react-datepicker-year-header {
  @apply text-sm font-semibold;
}

.react-datepicker__year-text {
  @apply text-sm rounded-full hover:rounded-full hover:bg-accent hover:font-medium hover:text-white;
}

.react-datepicker__year-text--selected,
.react-datepicker__year-text--keyboard-selected {
  @apply !bg-accent;
}

/* Video Player */

.player-wrapper {
  @apply relative;
}

.react-player {
  @apply absolute top-0 left-0;
}

/* Custom SVG for checkox and radioButtons in disabled status */
input[type='checkbox']:disabled,
input[type='radio']:disabled {
  background-image: url('data:image/svg+xml,%3csvg fill=%27white%27 fillRule=%27evenodd%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M19 13H5V11H19V13Z%27/%3e%3c/svg%3e');
  background-color: black;
  opacity: 0.1;
}

/* Custom style for inputs */
input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'],
input[type='search'] {
  @apply bg-gray-100 rounded border-none shadow-none text-base text-left text-black caret-accent h-11;
}
